import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useStatistic = () => {
  const toast = useToast();

  const getBalanceList = async (params) => {
    try {
      return await store.dispatch('statistic/getBalanceList', params);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of balance',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getStandingOrderReport = async (params) => {
    try {
      return await store.dispatch('statistic/getstandingOrderList', params);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of standing order',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    //Method
    getBalanceList,
    getStandingOrderReport,
  };
};

export default useStatistic;
